<template>
  <div class="noscrollmain">
    <common-top
      title="活跃度排行"
      :isShowSearch="false"
      @search="showModal = true"
      :isFilter="true"
      :sortList="sortList"
      @sortData="sortData"
    ></common-top>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div>
      <desc-com :startDate="startDate" :endDate="endDate"></desc-com>

      <div class="listRankTitle">
        <div
          v-for="(item, key) in rankCycle"
          :key="key"
          @click="chooseRankType(item)"
          :class="item == defaultRankCycle ? 'banganStyAcvive' : ''"
        >
          {{ item }}
        </div>
      </div>

      <dept-tab-com @chooseTab="chooseTabItem"></dept-tab-com>

      <list-com
        :columns="columns"
        :descWidth="true"
        :showList="showList"
        :showLoad="showLoad"
        :comHeight="comHeight"
      ></list-com>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import { wxConPost, getUserPm, getRadio } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
// import listRank from "../../components/listRankType2.vue";
import listCom from "../../components/listCom.vue";
import descCom from "../../components/descCom.vue";
import deptTabCom from "../../components/deptTabCom.vue";

import moment from "moment";

export default {
  name: "jifenRank",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    descCom,
    deptTabCom,
  },

  data() {
    return {
      comHeight: 7,
      curTabItem: {},

      isShowSearch: false, // 是否显示搜索框

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级

      showDetailStatus: false,
      detailInfo: {}, // 单位详情

      deptData: [],
      dataList: [],
      showList: [],
      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      startDate: "",
      endDate: "",
      rankCycle: ["周榜", "月榜"],
      defaultRankCycle: "周榜",
      columns: [
        {
          name: "阅读活跃",
          value: "countNum",
        },
        {
          name: "点赞活跃",
          value: "likeNum",
        },
        {
          name: "分享活跃",
          value: "shareNum",
        },
        {
          name: "评论活跃",
          value: "commentNum",
        },
        {
          name: "总活跃度",
          value: "活跃度",
        },
        {
          name: "人均活跃",
          value: "人均活跃度",
          color: "#ca3d6d",
        },
        "环比增长",
      ],
      sortList: [
        {
          name: "阅读活跃",
          value: "countNum",
        },
        {
          name: "点赞活跃",
          value: "likeNum",
        },
        {
          name: "分享活跃",
          value: "shareNum",
        },
        {
          name: "评论活跃",
          value: "commentNum",
        },
        {
          name: "总活跃度",
          value: "活跃度",
        },
        {
          name: "人均活跃",
          value: "averageActive",
        },
      ],
    };
  },
  methods: {
    getRankParam(type) {
      switch (type) {
        case "周榜":
          return "weekActivity";
        case "月榜":
          return "monthActivity";
        default:
          return "weekActivity";
      }
    },

    chooseTabItem(tabItem) {
      this.curTabItem = tabItem;
      // 查找部门为 childDeptList
      this.initRankGroup(tabItem);
    },
    initRankGroup(tabItem) {
      // console.log("initRankGroup------", tabItem);
      if (!_.isEmpty(tabItem) && tabItem) {
        const dataList = [];
        for (const iterator of this.dataList) {
          if (_.indexOf(tabItem.childDeptList, iterator.deptId) > -1) {
            dataList.push(iterator);
          }
        }
        this.showList = dataList;
      } else {
        this.showList = this.dataList;
      }
    },

    chooseRankType(type) {
      this.defaultRankCycle = type;
      const _type = this.getRankParam(type);
      this.getDeptStatus(_type);
    },
    async getDeptStatus(type) {
      try {
        this.showLoad = true;
        let _type = type || this.getRankParam();
        const _url = `/services/gttt-wxstats-mgt/activity/${_type}`;

        const ret = await wxConPost(_url);
        if (ret.errMsg) {
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
        this.showLoad = false;

        let deptData = _.get(ret, ["data", "data"], []);
        let timeArea = _.get(ret, ["data", "timeArea"], "");

        this.dealDateData(timeArea);
        await this.dealRankData(deptData);
        this.initRankGroup(this.curTabItem);

        this.getDeptRadioStatus(type);
      } catch (error) {
        console.log("----", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },

    async getDeptRadioStatus(type) {
      try {
        let _type = type || this.getRankParam();
        let _query = {
          date:
            _type.indexOf("week") > -1
              ? moment().subtract(1, "weeks").format("YYYY-MM-DD")
              : moment().subtract(1, "months").format("YYYY-MM-DD"),
        };
        const _url = `/services/gttt-wxstats-mgt/activity/${_type}`;

        const ret = await wxConPost(_url, _query);

        let deptData = _.get(ret, ["data", "data"], []);

        // console.log("getDeptRadioStatus-----", deptData[0]);
        if (deptData && deptData.length > 0) {
          // console.log("this.dataList----", this.dataList);
          for (const iterator of this.dataList) {
            // 计算环比差 当前榜deptId，历史榜deptId、_id
            const _huanBiData = _.find(deptData, (o) => {
              return (
                (o.deptId && o.deptId == iterator.deptId) ||
                (o._id && o._id == iterator.deptId)
              );
            });

            if (_huanBiData) {
              let allNum =
                _huanBiData["commentNum"] +
                _huanBiData["countNum"] +
                _huanBiData["likeNum"] +
                _huanBiData["shareNum"];

              // 环比增长率=（本期数-上期数）/上期数×100%
              iterator["环比增长"] = getRadio(iterator["活跃度"], allNum);

              // console.log(
              //   "-allNum-",
              //   allNum,
              //   iterator["活跃度"],
              //   iterator["环比增长"]
              // );
              // if (iterator.deptName == "怀化房建公寓段") {
              //   console.log(
              //     iterator,
              //     "-allNum-",
              //     iterator.deptName,
              //     iterator["环比增长"],
              //     allNum,
              //     iterator["活跃度"]
              //   );
              // }
            }
          }

          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      } catch (error) {
        console.log("----", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },
    // 处理人员数据
    dealRankData(data) {
      let dataList = [];

      for (const iterator of data) {
        if (iterator.deptName) {
          let allNum =
            iterator["commentNum"] +
            iterator["countNum"] +
            iterator["likeNum"] +
            iterator["shareNum"];

          let averageActive = 0;
          if (iterator.userStatusCount > 0) {
            averageActive = allNum / iterator.userStatusCount;
          }

          dataList.push(
            Object.assign(iterator, {
              活跃度: allNum,
              环比增长: "-",
              人均活跃度: averageActive.toFixed(2),
              averageActive,
            })
          );
        }
      }

      this.dataList = _.reverse(_.sortBy(dataList, ["averageActive"]));
      // console.log("----", this.dataList[0]);
    },
    //  "2022-03-21_2022-03-23"
    dealDateData(timeArea) {
      this.startDate = timeArea.slice(0, 10);
      this.endDate = timeArea.slice(11, 21);
    },
    // 数据排序
    sortData(data) {
      console.log("data----", data);
      if (data && data.value) {
        if (data.order == "aes") {
          // 升序
          this.showList = _.sortBy(this.showList, [data.value]);
        } else {
          // 降序
          this.showList = _.reverse(_.sortBy(this.showList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
  },
  async created() {
    this.getDeptStatus();
  },
};
</script>

<style scoped>
.listRankTitle {
  text-align: center;
  font-size: 23px;
  color: #fff;
  font-weight: 500;
  display: flex;
  /* height: 35px; */
  flex-wrap: wrap;
  line-height: 35px;
  font-size: 14px;
  border-radius: 4px;
  justify-content: center;
  margin: 10px 0;
}

.listRankTitle .banganStyAcvive {
  /* background: rgb(51, 124, 153); */
  /* background: #6f757f; */
  background: #386ba1;
  font-weight: bold;
  opacity: 1;
}
.listRankTitle div {
  opacity: 0.8;
  background: #b0b3b9;
  padding: 0px 18px;
  box-shadow: 0px 0px 9px 0 rgba(29, 32, 33, 0.28);
}
</style>
